import axios from "axios";
// import { logoutUser } from "./redux/actions/profile";

// axios.interceptors.request.use(
//     function (config) {
//       const lastLoggedInTime = localStorage.getItem("lastLoggedInTime")
//       if(lastLoggedInTime) {
//         const timeElapsed = Date.now() - lastLoggedInTime;
//         if (timeElapsed > 1000) {
//             store.dispatch(logoutUser());
//             window.location.href="/login"
//           }
//         }
//         return config;
//     },
//     function (error) {
//       return Promise.reject(error);
//     }
//   );

export default axios;
