import {
  GET_SUCCESS_MESSAGE,
  GET_ERROR_MESSAGE,
  START_LOADER,
  STOP_LOADER,
  SHOW_SIDEBAR,
  HIDE_SIDEBAR,
} from "../constants/actionTypes";
import { errorMessageFromResponse } from "../constants/commonFunctions";

export const handleSuccessMessage = (successMessage) => {
  return {
    type: GET_SUCCESS_MESSAGE,
    payload: successMessage,
  };
};
export const handleErrorMessage = (errorMessage) => {
  return {
    type: GET_ERROR_MESSAGE,
    payload: errorMessageFromResponse(errorMessage),
  };
};
export const handleSystemErrorMessage = (errorMessage) => {
  return {
    type: GET_ERROR_MESSAGE,
    payload: errorMessage?.message,
  };
};

export function startLoading() {
  return async (dispatch) => {
    try {
      dispatch({ type: START_LOADER, payload: {} });
    } catch (error) {
      dispatch(handleErrorMessage(error));
    }
  };
}
export function stopLoading() {
  return async (dispatch) => {
    try {
      dispatch({ type: STOP_LOADER, payload: {} });
    } catch (error) {
      dispatch(handleErrorMessage(error));
    }
  };
}
export function showSideBar() {
  return async (dispatch) => {
    try {
      dispatch({ type: SHOW_SIDEBAR, payload: {} });
    } catch (error) {
      dispatch(handleErrorMessage(error));
    }
  };
}
export function hideSideBar() {
  return async (dispatch) => {
    try {
      dispatch({ type: HIDE_SIDEBAR, payload: {} });
    } catch (error) {
      dispatch(handleErrorMessage(error));
    }
  };
}
