export const GET_SUCCESS_MESSAGE = "GET_SUCCESS_MESSAGE";

export const GET_ERROR_MESSAGE = "GET_ERROR_MESSAGE";
export const RESET_ERROR_MESSAGE = "RESET_ERROR_MESSAGE";
export const ERROR_STATUS_CODE = "ERROR_STATUS_CODE";

export const START_LOADER = "START_LOADER";
export const STOP_LOADER = "STOP_LOADER";

export const SHOW_SIDEBAR = "SHOW_SIDEBAR";
export const HIDE_SIDEBAR = "HIDE_SIDEBAR";

export const GET_TAX_SETTINGS = "GET_TAX_SETTINGS";
export const POST_TAX_SETTINGS = "POST_TAX_SETTINGS";
export const UPDATE_TAX_SETTINGS = "UPDATE_TAX_SETTINGS";

export const GET_SERVICE_PROVIDER_COMPANIES = "GET_SERVICE_PROVIDER_COMPANIES";
export const POST_SERVICE_PROVIDER_COMPANIES =
  "POST_SERVICE_PROVIDER_COMPANIES";
export const PUT_SERVICE_PROVIDER_COMPANIES = "PUT_SERVICE_PROVIDER_COMPANIES";
export const DELETE_SERVICE_PROVIDER_COMPANIES =
  "DELETE_SERVICE_PROVIDER_COMPANIES";

export const GET_TAX_COMPUTATION_DETAILS = "GET_TAX_COMPUTATION_DETAILS";
export const GET_TAX_COMPUTATION_DETAIL = "GET_TAX_COMPUTATION_DETAIL";
export const POST_TAX_COMPUTATION_DETAILS = "POST_TAX_COMPUTATION_DETAILS";
export const PUT_TAX_COMPUTATION_DETAILS = "PUT_TAX_COMPUTATION_DETAILS";
export const DELETE_TAX_COMPUTATION_DETAILS = "DELETE_TAX_COMPUTATION_DETAILS";

export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const POST_CUSTOMERS = "POST_CUSTOMERS";
export const PUT_CUSTOMERS = "PUT_CUSTOMERS";
export const DELETE_CUSTOMERS = "DELETE_CUSTOMERS";

export const GET_USERS = "GET_USERS";
export const POST_USERS = "POST_USERS";
export const PUT_USERS = "PUT_USERS";
export const DELETE_USERS = "DELETE_USERS";

export const GET_USER_ACCESS_LEVELS = "GET_USER_ACCESS_LEVELS";
export const POST_USER_ACCESS_LEVELS = "POST_USER_ACCESS_LEVELS";
export const PUT_USER_ACCESS_LEVELS = "PUT_USER_ACCESS_LEVELS";
export const DELETE_USER_ACCESS_LEVELS = "DELETE_USER_ACCESS_LEVELS";

export const GET_PROFILE = "GET_PROFILE";
export const POST_PROFILE = "POST_PROFILE";
export const PUT_PROFILE = "PUT_PROFILE";
export const DELETE_PROFILE = "DELETE_PROFILE";

export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const ASK_TO_CONTINUE = "ASK_TO_CONTINUE";

export const GET_TRADING_METHOD_SCHEDULE_CONTENT =
  "GET_TRADING_METHOD_SCHEDULE_CONTENT";
export const POST_TRADING_METHOD_SCHEDULE_CONTENT =
  "POST_TRADING_METHOD_SCHEDULE_CONTENT";
export const PUT_TRADING_METHOD_SCHEDULE_CONTENT =
  "PUT_TRADING_METHOD_SCHEDULE_CONTENT";
export const DELETE_TRADING_METHOD_SCHEDULE_CONTENT =
  "DELETE_TRADING_METHOD_SCHEDULE_CONTENT";

export const GET_TRADING_METHOD_SCHEDULE1 = "GET_TRADING_METHOD_SCHEDULE1";
export const POST_TRADING_METHOD_SCHEDULE1 = "POST_TRADING_METHOD_SCHEDULE1";
export const PUT_TRADING_METHOD_SCHEDULE1 = "PUT_TRADING_METHOD_SCHEDULE1";
export const DELETE_TRADING_METHOD_SCHEDULE1 =
  "DELETE_TRADING_METHOD_SCHEDULE1";

export const GET_SCHEDULE_LINKS = "GET_SCHEDULE_LINKS";
export const POST_SCHEDULE_LINKS = "POST_SCHEDULE_LINKS";
export const PUT_SCHEDULE_LINKS = "PUT_SCHEDULE_LINKS";
export const DELETE_SCHEDULE_LINKS = "DELETE_SCHEDULE_LINKS";

export const GET_PROFIT_AND_LOSS_STATEMENT = "GET_PROFIT_AND_LOSS_STATEMENT";
export const POST_PROFIT_AND_LOSS_STATEMENT = "POST_PROFIT_AND_LOSS_STATEMENT";
export const PUT_PROFIT_AND_LOSS_STATEMENT = "PUT_PROFIT_AND_LOSS_STATEMENT";
export const DELETE_PROFIT_AND_LOSS_STATEMENT =
  "DELETE_PROFIT_AND_LOSS_STATEMENT";

export const GET_RENTAL_INCOME = "GET_RENTAL_INCOME";
export const POST_RENTAL_INCOME = "POST_RENTAL_INCOME";
export const PUT_RENTAL_INCOME = "PUT_RENTAL_INCOME";
export const DELETE_RENTAL_INCOME =
  "DELETE_RENTAL_INCOME";

export const GET_RENTAL_INCOME_LIST = "GET_RENTAL_INCOME_LIST";
export const POST_RENTAL_INCOME_LIST = "POST_RENTAL_INCOME_LIST";
export const PUT_RENTAL_INCOME_LIST = "PUT_RENTAL_INCOME_LIST";
export const DELETE_RENTAL_INCOME_LIST = "DELETE_RENTAL_INCOME_LIST";

export const GET_EXPENSE_ACCOUNT_FULL = "GET_EXPENSE_ACCOUNT_FULL";
export const POST_EXPENSE_ACCOUNT_FULL = "POST_EXPENSE_ACCOUNT_FULL";
export const PUT_EXPENSE_ACCOUNT_FULL = "PUT_EXPENSE_ACCOUNT_FULL";
export const DELETE_EXPENSE_ACCOUNT_FULL =
  "DELETE_EXPENSE_ACCOUNT_FULL";

export const GET_EXPENSE_ACCOUNT_FULL_LIST = "GET_EXPENSE_ACCOUNT_FULL_LIST";
export const POST_EXPENSE_ACCOUNT_FULL_LIST = "POST_EXPENSE_ACCOUNT_FULL_LIST";
export const PUT_EXPENSE_ACCOUNT_FULL_LIST = "PUT_EXPENSE_ACCOUNT_FULL_LIST";
export const DELETE_EXPENSE_ACCOUNT_FULL_LIST = "DELETE_EXPENSE_ACCOUNT_FULL_LIST";

export const GET_EXPENSE_ACCOUNT_ADJUSTMENT = "GET_EXPENSE_ACCOUNT_ADJUSTMENT";
export const POST_EXPENSE_ACCOUNT_ADJUSTMENT = "POST_EXPENSE_ACCOUNT_ADJUSTMENT";
export const PUT_EXPENSE_ACCOUNT_ADJUSTMENT = "PUT_EXPENSE_ACCOUNT_ADJUSTMENT";
export const DELETE_EXPENSE_ACCOUNT_ADJUSTMENT =
  "DELETE_EXPENSE_ACCOUNT_ADJUSTMENT";

export const GET_EXPENSE_ACCOUNT_ADJUSTMENT_LIST = "GET_EXPENSE_ACCOUNT_ADJUSTMENT_LIST";
export const POST_EXPENSE_ACCOUNT_ADJUSTMENT_LIST = "POST_EXPENSE_ACCOUNT_ADJUSTMENT_LIST";
export const PUT_EXPENSE_ACCOUNT_ADJUSTMENT_LIST = "PUT_EXPENSE_ACCOUNT_ADJUSTMENT_LIST";
export const DELETE_EXPENSE_ACCOUNT_ADJUSTMENT_LIST = "DELETE_EXPENSE_ACCOUNT_ADJUSTMENT_LIST";

export const GET_INTEREST_ADJUSTMENT = "GET_INTEREST_ADJUSTMENT";
export const POST_INTEREST_ADJUSTMENT = "POST_INTEREST_ADJUSTMENT";
export const PUT_INTEREST_ADJUSTMENT = "PUT_INTEREST_ADJUSTMENT";
export const DELETE_INTEREST_ADJUSTMENT =
  "DELETE_INTEREST_ADJUSTMENT";

export const GET_INTEREST_ADJUSTMENT_LIST = "GET_INTEREST_ADJUSTMENT_LIST";
export const POST_INTEREST_ADJUSTMENT_LIST = "POST_INTEREST_ADJUSTMENT_LIST";
export const PUT_INTEREST_ADJUSTMENT_LIST = "PUT_INTEREST_ADJUSTMENT_LIST";
export const DELETE_INTEREST_ADJUSTMENT_LIST =
  "DELETE_INTEREST_ADJUSTMENT_LIST";

export const GET_DIVIDEND_INCOME = "GET_DIVIDEND_INCOME";
export const POST_DIVIDEND_INCOME = "POST_DIVIDEND_INCOME";
export const PUT_DIVIDEND_INCOME = "PUT_DIVIDEND_INCOME";
export const DELETE_DIVIDEND_INCOME =
  "DELETE_DIVIDEND_INCOME";

export const GET_DIVIDEND_INCOME_LIST = "GET_DIVIDEND_INCOME_LIST";
export const POST_DIVIDEND_INCOME_LIST = "POST_DIVIDEND_INCOME_LIST";
export const PUT_DIVIDEND_INCOME_LIST = "PUT_DIVIDEND_INCOME_LIST";
export const DELETE_DIVIDEND_INCOME_LIST =
  "DELETE_DIVIDEND_INCOME_LIST";

export const GET_FOREIGN_TRADE_INCOME = "GET_FOREIGN_TRADE_INCOME";
export const POST_FOREIGN_TRADE_INCOME = "POST_FOREIGN_TRADE_INCOME";
export const PUT_FOREIGN_TRADE_INCOME = "PUT_FOREIGN_TRADE_INCOME";
export const DELETE_FOREIGN_TRADE_INCOME =
  "DELETE_FOREIGN_TRADE_INCOME";

export const GET_FOREIGN_TRADE_INCOME_LIST = "GET_FOREIGN_TRADE_INCOME_LIST";
export const POST_FOREIGN_TRADE_INCOME_LIST = "POST_FOREIGN_TRADE_INCOME_LIST";
export const PUT_FOREIGN_TRADE_INCOME_LIST = "PUT_FOREIGN_TRADE_INCOME_LIST";
export const DELETE_FOREIGN_TRADE_INCOME_LIST =
  "DELETE_FOREIGN_TRADE_INCOME_LIST";
  
export const GET_MEDICAL_EXPENSES = "GET_MEDICAL_EXPENSES";
export const POST_MEDICAL_EXPENSES = "POST_MEDICAL_EXPENSES";
export const PUT_MEDICAL_EXPENSES = "PUT_MEDICAL_EXPENSES";
export const DELETE_MEDICAL_EXPENSES =
  "DELETE_MEDICAL_EXPENSES";

export const GET_MEDICAL_EXPENSES_LIST = "GET_MEDICAL_EXPENSES_LIST";
export const POST_MEDICAL_EXPENSES_LIST = "POST_MEDICAL_EXPENSES_LIST";
export const PUT_MEDICAL_EXPENSES_LIST = "PUT_MEDICAL_EXPENSES_LIST";
export const DELETE_MEDICAL_EXPENSES_LIST =
  "DELETE_MEDICAL_EXPENSES_LIST";

export const GET_PROVISIONAL_ALLOWANCES = "GET_PROVISIONAL_ALLOWANCES";
export const POST_PROVISIONAL_ALLOWANCES = "POST_PROVISIONAL_ALLOWANCES";
export const PUT_PROVISIONAL_ALLOWANCES = "PUT_PROVISIONAL_ALLOWANCES";
export const DELETE_PROVISIONAL_ALLOWANCES =
  "DELETE_PROVISIONAL_ALLOWANCES";

export const GET_PROVISIONAL_ALLOWANCES_LIST = "GET_PROVISIONAL_ALLOWANCES_LIST";
export const POST_PROVISIONAL_ALLOWANCES_LIST = "POST_PROVISIONAL_ALLOWANCES_LIST";
export const PUT_PROVISIONAL_ALLOWANCES_LIST = "PUT_PROVISIONAL_ALLOWANCES_LIST";
export const DELETE_PROVISIONAL_ALLOWANCES_LIST =
  "DELETE_PROVISIONAL_ALLOWANCES_LIST";

export const GET_OTHER_INCOME = "GET_OTHER_INCOME";
export const POST_OTHER_INCOME = "POST_OTHER_INCOME";
export const PUT_OTHER_INCOME = "PUT_OTHER_INCOME";
export const DELETE_OTHER_INCOME =
  "DELETE_OTHER_INCOME";

export const GET_OTHER_INCOME_LIST = "GET_OTHER_INCOME_LIST";
export const POST_OTHER_INCOME_LIST = "POST_OTHER_INCOME_LIST";
export const PUT_OTHER_INCOME_LIST = "PUT_OTHER_INCOME_LIST";
export const DELETE_OTHER_INCOME_LIST =
  "DELETE_OTHER_INCOME_LIST";

export const GET_UNREMITTED_INCOME = "GET_UNREMITTED_INCOME";
export const POST_UNREMITTED_INCOME = "POST_UNREMITTED_INCOME";
export const PUT_UNREMITTED_INCOME = "PUT_UNREMITTED_INCOME";
export const DELETE_UNREMITTED_INCOME =
  "DELETE_UNREMITTED_INCOME";

export const GET_UNREMITTED_INCOME_LIST = "GET_UNREMITTED_INCOME_LIST";
export const POST_UNREMITTED_INCOME_LIST = "POST_UNREMITTED_INCOME_LIST";
export const PUT_UNREMITTED_INCOME_LIST = "PUT_UNREMITTED_INCOME_LIST";
export const DELETE_UNREMITTED_INCOME_LIST =
  "DELETE_UNREMITTED_INCOME_LIST";

export const GET_TRUST_REIT_DISTRIBUTION = "GET_TRUST_REIT_DISTRIBUTION";
export const POST_TRUST_REIT_DISTRIBUTION = "POST_TRUST_REIT_DISTRIBUTION";
export const PUT_TRUST_REIT_DISTRIBUTION = "PUT_TRUST_REIT_DISTRIBUTION";
export const DELETE_TRUST_REIT_DISTRIBUTION =
  "DELETE_TRUST_REIT_DISTRIBUTION";

export const GET_TRUST_REIT_DISTRIBUTION_LIST = "GET_TRUST_REIT_DISTRIBUTION_LIST";
export const POST_TRUST_REIT_DISTRIBUTION_LIST = "POST_TRUST_REIT_DISTRIBUTION_LIST";
export const PUT_TRUST_REIT_DISTRIBUTION_LIST = "PUT_TRUST_REIT_DISTRIBUTION_LIST";
export const DELETE_TRUST_REIT_DISTRIBUTION_LIST =
  "DELETE_TRUST_REIT_DISTRIBUTION_LIST";

export const GET_INTEREST_INCOME = "GET_INTEREST_INCOME";
export const POST_INTEREST_INCOME = "POST_INTEREST_INCOME";
export const PUT_INTEREST_INCOME = "PUT_INTEREST_INCOME";
export const DELETE_INTEREST_INCOME =
  "DELETE_INTEREST_INCOME";

export const GET_GROUP_RELIEF_DETAILS = "GET_GROUP_RELIEF_DETAILS";
export const POST_GROUP_RELIEF_DETAILS = "POST_GROUP_RELIEF_DETAILS";
export const PUT_GROUP_RELIEF_DETAILS = "PUT_GROUP_RELIEF_DETAILS";
export const DELETE_GROUP_RELIEF_DETAILS =
  "DELETE_GROUP_RELIEF_DETAILS";


export const GET_CARRY_BACK_RELIEF_DETAILS = "GET_CARRY_BACK_RELIEF_DETAILS";
export const POST_CARRY_BACK_RELIEF_DETAILS = "POST_CARRY_BACK_RELIEF_DETAILS";
export const PUT_CARRY_BACK_RELIEF_DETAILS = "PUT_CARRY_BACK_RELIEF_DETAILS";
export const DELETE_CARRY_BACK_RELIEF_DETAILS =
  "DELETE_CARRY_BACK_RELIEF_DETAILS"; 

export const GET_FRS_116_ADJUSTMENT_DETAILS = "GET_FRS_116_ADJUSTMENT_DETAILS";
export const POST_FRS_116_ADJUSTMENT_DETAILS = "POST_FRS_116_ADJUSTMENT_DETAILS";
export const PUT_FRS_116_ADJUSTMENT_DETAILS = "PUT_FRS_116_ADJUSTMENT_DETAILS";
export const DELETE_FRS_116_ADJUSTMENT_DETAILS =
  "DELETE_FRS_116_ADJUSTMENT_DETAILS";

export const GET_FRS_116_ADJUSTMENTS_LIST = "GET_FRS_116_ADJUSTMENTS_LIST";
export const POST_FRS_116_ADJUSTMENTS_LIST = "POST_FRS_116_ADJUSTMENTS_LIST";
export const PUT_FRS_116_ADJUSTMENTS_LIST = "PUT_FRS_116_ADJUSTMENTS_LIST";
export const DELETE_FRS_116_ADJUSTMENTS_LIST =
  "DELETE_FRS_116_ADJUSTMENTS_LIST";

export const GET_DONATIONS = "GET_DONATIONS";
export const POST_DONATIONS = "POST_DONATIONS";
export const PUT_DONATIONS = "PUT_DONATIONS";
export const DELETE_DONATIONS =
  "DELETE_DONATIONS";

export const GET_INCOME_TAX_COMPUTATION = "GET_INCOME_TAX_COMPUTATION";
export const POST_INCOME_TAX_COMPUTATION = "POST_INCOME_TAX_COMPUTATION";
export const PUT_INCOME_TAX_COMPUTATION = "PUT_INCOME_TAX_COMPUTATION";
export const DELETE_INCOME_TAX_COMPUTATION =
  "DELETE_INCOME_TAX_COMPUTATION";

export const GET_DONATIONS_LIST = "GET_DONATIONS_LIST";
export const POST_DONATIONS_LIST = "POST_DONATIONS_LIST";
export const PUT_DONATIONS_LIST = "PUT_DONATIONS_LIST";
export const DELETE_DONATIONS_LIST =
  "DELETE_DONATIONS_LIST";

export const GET_TRAINING_EXPENDITURE = "GET_TRAINING_EXPENDITURE";
export const POST_TRAINING_EXPENDITURE = "POST_TRAINING_EXPENDITURE";
export const PUT_TRAINING_EXPENDITURE = "PUT_TRAINING_EXPENDITURE";
export const DELETE_TRAINING_EXPENDITURE =
  "DELETE_TRAINING_EXPENDITURE";

export const GET_TRAINING_EXPENDITURE_LIST = "GET_TRAINING_EXPENDITURE_LIST";
export const POST_TRAINING_EXPENDITURE_LIST = "POST_TRAINING_EXPENDITURE_LIST";
export const PUT_TRAINING_EXPENDITURE_LIST = "PUT_TRAINING_EXPENDITURE_LIST";
export const DELETE_TRAINING_EXPENDITURE_LIST =
  "DELETE_TRAINING_EXPENDITURE_LIST";


export const GET_INTELLECTUAL_PROPERTY_RIGHTS = "GET_INTELLECTUAL_PROPERTY_RIGHTS";
export const POST_INTELLECTUAL_PROPERTY_RIGHTS = "POST_INTELLECTUAL_PROPERTY_RIGHTS";
export const PUT_INTELLECTUAL_PROPERTY_RIGHTS = "PUT_INTELLECTUAL_PROPERTY_RIGHTS";
export const DELETE_INTELLECTUAL_PROPERTY_RIGHTS =
  "DELETE_INTELLECTUAL_PROPERTY_RIGHTS";

export const GET_INTELLECTUAL_PROPERTY_RIGHTS_LIST = "GET_INTELLECTUAL_PROPERTY_RIGHTS_LIST";
export const POST_INTELLECTUAL_PROPERTY_RIGHTS_LIST = "POST_INTELLECTUAL_PROPERTY_RIGHTS_LIST";
export const PUT_INTELLECTUAL_PROPERTY_RIGHTS_LIST = "PUT_INTELLECTUAL_PROPERTY_RIGHTS_LIST";
export const DELETE_INTELLECTUAL_PROPERTY_RIGHTS_LIST =
  "DELETE_INTELLECTUAL_PROPERTY_RIGHTS_LIST";

export const GET_FURTHER_OTHER_DEDUCTIONS = "GET_FURTHER_OTHER_DEDUCTIONS";
export const POST_FURTHER_OTHER_DEDUCTIONS = "POST_FURTHER_OTHER_DEDUCTIONS";
export const PUT_FURTHER_OTHER_DEDUCTIONS = "PUT_FURTHER_OTHER_DEDUCTIONS";
export const DELETE_FURTHER_OTHER_DEDUCTIONS =
  "DELETE_FURTHER_OTHER_DEDUCTIONS";

export const GET_FURTHER_OTHER_DEDUCTIONS_LIST = "GET_FURTHER_OTHER_DEDUCTIONS_LIST";
export const POST_FURTHER_OTHER_DEDUCTIONS_LIST = "POST_FURTHER_OTHER_DEDUCTIONS_LIST";
export const PUT_FURTHER_OTHER_DEDUCTIONS_LIST = "PUT_FURTHER_OTHER_DEDUCTIONS_LIST";
export const DELETE_FURTHER_OTHER_DEDUCTIONS_LIST =
  "DELETE_FURTHER_OTHER_DEDUCTIONS_LIST";

export const GET_INTELLECTUAL_PROPERTY = "GET_INTELLECTUAL_PROPERTY";
export const POST_INTELLECTUAL_PROPERTY = "POST_INTELLECTUAL_PROPERTY";
export const PUT_INTELLECTUAL_PROPERTY = "PUT_INTELLECTUAL_PROPERTY";
export const DELETE_INTELLECTUAL_PROPERTY =
  "DELETE_INTELLECTUAL_PROPERTY";

export const GET_INTELLECTUAL_PROPERTY_LIST = "GET_INTELLECTUAL_PROPERTY_LIST";
export const POST_INTELLECTUAL_PROPERTY_LIST = "POST_INTELLECTUAL_PROPERTY_LIST";
export const PUT_INTELLECTUAL_PROPERTY_LIST = "PUT_INTELLECTUAL_PROPERTY_LIST";
export const DELETE_INTELLECTUAL_PROPERTY_LIST =
  "DELETE_INTELLECTUAL_PROPERTY_LIST";

export const GET_TAX_COMPUTATION_ADJUSTMENT = "GET_TAX_COMPUTATION_ADJUSTMENT";
export const POST_TAXGET_TAX_COMPUTATION_ADJUSTMENT = "POST_TAX_COMPUTATION_ADJUSTMENT";
export const PUT_TAXGET_TAX_COMPUTATION_ADJUSTMENT = "PUT_TAX_COMPUTATION_ADJUSTMENT";
export const DELETE_TAXGET_TAX_COMPUTATION_ADJUSTMENT =
  "DELETE_TAX_COMPUTATION_ADJUSTMENT";

export const GET_TAX_COMPUTATION_ADJUSTMENTS = "GET_TAX_COMPUTATION_ADJUSTMENTS";
export const POST_TAX_COMPUTATION_ADJUSTMENTS = "POST_TAX_COMPUTATION_ADJUSTMENTS";
export const PUT_TAX_COMPUTATION_ADJUSTMENTS = "PUT_TAX_COMPUTATION_ADJUSTMENTS";
export const DELETE_TAX_COMPUTATION_ADJUSTMENTS =
  "DELETE_TAX_COMPUTATION_ADJUSTMENTS";

export const GET_UNUTILISED_ITEMS_BROUGHT_FORWARD_CARRIED_FORWARD = "GET_UNUTILISED_ITEMS_BROUGHT_FORWARD_CARRIED_FORWARD";
export const POST_UNUTILISED_ITEMS_BROUGHT_FORWARD_CARRIED_FORWARD = "POST_UNUTILISED_ITEMS_BROUGHT_FORWARD_CARRIED_FORWARD";
export const PUT_UNUTILISED_ITEMS_BROUGHT_FORWARD_CARRIED_FORWARD = "PUT_UNUTILISED_ITEMS_BROUGHT_FORWARD_CARRIED_FORWARD";
export const DELETE_UNUTILISED_ITEMS_BROUGHT_FORWARD_CARRIED_FORWARD =
  "DELETE_UNUTILISED_ITEMS_BROUGHT_FORWARD_CARRIED_FORWARD";

export const GET_RENOVATION_AND_REFURBISHMENT_WORKS = "GET_RENOVATION_AND_REFURBISHMENT_WORKS";
export const POST_RENOVATION_AND_REFURBISHMENT_WORKS = "POST_RENOVATION_AND_REFURBISHMENT_WORKS";
export const PUT_RENOVATION_AND_REFURBISHMENT_WORKS = "PUT_RENOVATION_AND_REFURBISHMENT_WORKS";
export const DELETE_RENOVATION_AND_REFURBISHMENT_WORKS =
  "DELETE_RENOVATION_AND_REFURBISHMENT_WORKS";

export const GET_INTEREST_INCOME_LIST = "GET_INTEREST_INCOME_LIST";
export const POST_INTEREST_INCOME_LIST = "POST_INTEREST_INCOME_LIST";
export const PUT_INTEREST_INCOME_LIST = "PUT_INTEREST_INCOME_LIST";
export const DELETE_INTEREST_INCOME_LIST =
  "DELETE_INTEREST_INCOME_LIST";

  export const GET_FIXED_ASSETS_DETAILS = "GET_FIXED_ASSETS_DETAILS";
  export const POST_FIXED_ASSETS_DETAILS = "POST_FIXED_ASSETS_DETAILS";
  export const PUT_FIXED_ASSETS_DETAILS = "PUT_FIXED_ASSETS_DETAILS";
  export const DELETE_FIXED_ASSETS_DETAILS =
  "DELETE_FIXED_ASSETS_DETAILS";

  export const GET_FIXED_ASSETS_LIST = "GET_FIXED_ASSETS_LIST";
  export const POST_FIXED_ASSETS_LIST = "POST_FIXED_ASSETS_LIST";
  export const PUT_FIXED_ASSETS_LIST = "PUT_FIXED_ASSETS_LIST";
  export const DELETE_FIXED_ASSETS_LIST =
  "DELETE_FIXED_ASSETS_LIST";

export const GET_CATEGORIES_LIST = "GET_CATEGORIES_LIST";
export const GET_WORKING_LIFE_YEARS = "GET_WORKING_LIFE_YEARS";
export const POST_CATEGORY_OPTION = "POST_CATEGORY_OPTION";
export const POST_WORKING_LIFE_YEARS_OPTION = "POST_WORKING_LIFE_YEARS_OPTION";
export const EDIT_CATEGORY_OPTION = "EDIT_CATEGORY_OPTION";
export const EDIT_WORKING_LIFE_YEARS_OPTION = "EDIT_WORKING_LIFE_YEARS_OPTION";
export const DELETE_CATEGORY_OPTION = "DELETE_CATEGORY_OPTION";
export const DELETE_WORKING_LIFE_YEARS_OPTION = "DELETE_WORKING_LIFE_YEARS_OPTION";

export const GET_DISPOSAL_FIXED_ASSETS_LIST = "GET_DISPOSAL_FIXED_ASSETS_LIST";
export const GET_DISPOSAL_FIXED_ASSETS_DETAILS = "GET_DISPOSAL_FIXED_ASSETS_DETAILS";
export const POST_DISPOSAL_FIXED_ASSETS = "POST_DISPOSAL_FIXED_ASSETS";
export const PUT_DISPOSAL_FIXED_ASSETS = "PUT_DISPOSAL_FIXED_ASSETS";
export const DELETE_DISPOSAL_FIXED_ASSETS =
"DELETE_DISPOSAL_FIXED_ASSETS";

export const GET_SHAREHOLDING_TEST_CAPITAL_ALLOWANCES_LIST = "GET_SHAREHOLDING_TEST_CAPITAL_ALLOWANCES_LIST";
export const GET_SHAREHOLDING_TEST_CAPITAL_ALLOWANCES_DETAILS = "GET_SHAREHOLDING_TEST_CAPITAL_ALLOWANCES_DETAILS";
export const POST_SHAREHOLDING_TEST_CAPITAL_ALLOWANCES = "POST_SHAREHOLDING_TEST_CAPITAL_ALLOWANCES";
export const PUT_SHAREHOLDING_TEST_CAPITAL_ALLOWANCES = "PUT_SHAREHOLDING_TEST_CAPITAL_ALLOWANCES";
export const DELETE_SHAREHOLDING_TEST_CAPITAL_ALLOWANCES =
"DELETE_SHAREHOLDING_TEST_CAPITAL_ALLOWANCES";

export const GET_SHAREHOLDING_TEST_UNABSORBED_LOSSES_LIST = "GET_SHAREHOLDING_TEST_UNABSORBED_LOSSES_LIST";
export const GET_SHAREHOLDING_TEST_UNABSORBED_LOSSES_DETAILS = "GET_SHAREHOLDING_TEST_UNABSORBED_LOSSES_DETAILS";
export const POST_SHAREHOLDING_TEST_UNABSORBED_LOSSES = "POST_SHAREHOLDING_TEST_UNABSORBED_LOSSES";
export const PUT_SHAREHOLDING_TEST_UNABSORBED_LOSSES = "PUT_SHAREHOLDING_TEST_UNABSORBED_LOSSES";
export const DELETE_SHAREHOLDING_TEST_UNABSORBED_LOSSES =
"DELETE_SHAREHOLDING_TEST_UNABSORBED_LOSSES";

export const POST_FTC_CALCULATION = "POST_FTC_CALCULATION";
export const GET_FTC_CALCULATION = "GET_FTC_CALCULATION";
export const PUT_FTC_CALCULATION = "PUT_FTC_CALCULATION";

export const POST_PARTNER_INNOVATION = "POST_PARTNER_INNOVATION";
export const GET_PARTNER_INNOVATION_DETAILS = "GET_PARTNER_INNOVATION_DETAILS";
export const GET_PARTNER_INNOVATION_LIST = "GET_PARTNER_INNOVATION_LIST";
export const PUT_PARTNER_INNOVATION = "PUT_PARTNER_INNOVATION";
export const DELETE_PARTNER_INNOVATION = "DELETE_PARTNER_INNOVATION";

export const POST_RESEARCH_AND_DEVELOPMENT = "POST_RESEARCH_AND_DEVELOPMENT";
export const GET_RESEARCH_AND_DEVELOPMENT_DETAILS = "GET_RESEARCH_AND_DEVELOPMENT_DETAILS";
export const GET_RESEARCH_AND_DEVELOPMENT_LIST = "GET_RESEARCH_AND_DEVELOPMENT_LIST";
export const PUT_RESEARCH_AND_DEVELOPMENT = "PUT_RESEARCH_AND_DEVELOPMENT";
export const DELETE_RESEARCH_AND_DEVELOPMENT = "DELETE_RESEARCH_AND_DEVELOPMENT";

export const POST_FORM_C = "POST_FORM_C";
export const GET_FORM_C_DETAILS = "GET_FORM_C_DETAILS";
export const GET_FORM_C_LIST = "GET_FORM_C_LIST";
export const PUT_FORM_C = "PUT_FORM_C";

export const POST_FORM_CS = "POST_FORM_CS";
export const GET_FORM_CS_DETAILS = "GET_FORM_CS_DETAILS";
export const GET_FORM_CS_LIST = "GET_FORM_CS_LIST";
export const PUT_FORM_CS = "PUT_FORM_CS";

export const POST_FORM_CS_LITE = "POST_FORM_CS_LITE";
export const GET_FORM_CS_LITE_DETAILS = "GET_FORM_CS_LITE_DETAILS";
export const GET_FORM_CS_LITE_LIST = "GET_FORM_CS_LITE_LIST";
export const PUT_FORM_CS_LITE = "PUT_FORM_CS_LITE";

export const SHOW_TIME_EXPIRED_MODAL = "SHOW_TIME_EXPIRED_MODAL"
export const HIDE_TIME_EXPIRED_MODAL = "HIDE_TIME_EXPIRED_MODAL"
