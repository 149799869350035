export const GET_TAX_SETTINGS_API = `${process.env.REACT_APP_API_BASE_URL}/tax_settings`;
export const POST_TAX_SETTINGS_API = `${process.env.REACT_APP_API_BASE_URL}/tax_settings/`;

export const GET_SERVICE_PROVIDER_COMPANIES_API = `${process.env.REACT_APP_API_BASE_URL}/service_provider_companies/`;
export const POST_SERVICE_PROVIDER_COMPANIES_API = `${process.env.REACT_APP_API_BASE_URL}/service_provider_companies/`;

export const GET_CUSTOMERS_API = `${process.env.REACT_APP_API_BASE_URL}/customers/`;
export const POST_CUSTOMERS_API = `${process.env.REACT_APP_API_BASE_URL}/customers/`;
export const GET_CUSTOMER_BY_COMPANY_NAME_API = `${process.env.REACT_APP_API_BASE_URL}/customer/customer_by_company_name`;

export const GET_TAX_COMPUTATION_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}/tax_computation_details/`;
export const POST_TAX_COMPUTATION_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}/tax_computation_details/`;

export const GET_EXPENSE_ACCOUNT_FULL_API = `${process.env.REACT_APP_API_BASE_URL}/expense_account_full/`;
export const POST_EXPENSE_ACCOUNT_FULL_API = `${process.env.REACT_APP_API_BASE_URL}/expense_account_full/`;

export const GET_EXPENSE_ACCOUNT_ADJUSTMENT_API = `${process.env.REACT_APP_API_BASE_URL}/expense_account_adjustment/`;
export const POST_EXPENSE_ACCOUNT_ADJUSTMENT_API = `${process.env.REACT_APP_API_BASE_URL}/expense_account_adjustment/`;

export const GET_USERS_API = `${process.env.REACT_APP_API_BASE_URL}/user/`;
export const POST_USERS_API = `${process.env.REACT_APP_API_BASE_URL}/user/`;

export const GET_PROFILE_API = `${process.env.REACT_APP_API_BASE_URL}/user`;
export const POST_PROFILE_API = `${process.env.REACT_APP_API_BASE_URL}/user/`;

export const GET_USER_ACCESS_LEVELS_API = `${process.env.REACT_APP_API_BASE_URL}/group/`;
export const POST_USER_ACCESS_LEVELS_API = `${process.env.REACT_APP_API_BASE_URL}/group/`;

export const CHANGE_PASSWORD_API = `${process.env.REACT_APP_API_BASE_URL}/users/change_password/`;
export const LOGIN_API = `${process.env.REACT_APP_API_BASE_URL}/users/login/`;
export const LOGOUT_API = `${process.env.REACT_APP_API_BASE_URL}/user-activity/logout_register/`;

export const GET_TRADING_METHOD_SCHEDULE_CONTENT_API = `${process.env.REACT_APP_API_BASE_URL}/trading_content/`;
export const POST_TRADING_METHOD_SCHEDULE_CONTENT_API = `${process.env.REACT_APP_API_BASE_URL}/trading_content/`;

export const GET_TRADING_METHOD_SCHEDULE1_API = `${process.env.REACT_APP_API_BASE_URL}/trading_schedule01/`;
export const POST_TRADING_METHOD_SCHEDULE1_API = `${process.env.REACT_APP_API_BASE_URL}/trading_schedule01/`;

export const GET_PROFIT_AND_LOSS_STATEMENT_API = `${process.env.REACT_APP_API_BASE_URL}/trading_profit_and_loss_statement/`;
export const POST_PROFIT_AND_LOSS_STATEMENT_API = `${process.env.REACT_APP_API_BASE_URL}/trading_profit_and_loss_statement/`;


export const GET_RENTAL_INCOME_API = `${process.env.REACT_APP_API_BASE_URL}/rental_income/`;
export const POST_RENTAL_INCOME_API = `${process.env.REACT_APP_API_BASE_URL}/rental_income/`;

export const GET_DIVIDEND_INCOME_API = `${process.env.REACT_APP_API_BASE_URL}/dividend_income/`;
export const POST_DIVIDEND_INCOME_API = `${process.env.REACT_APP_API_BASE_URL}/dividend_income/`;

export const GET_FOREIGN_TRADE_INCOME_API = `${process.env.REACT_APP_API_BASE_URL}/foreign_trade_income/`;
export const POST_FOREIGN_TRADE_INCOME_API = `${process.env.REACT_APP_API_BASE_URL}/foreign_trade_income/`;

export const GET_MEDICAL_EXPENSES_API = `${process.env.REACT_APP_API_BASE_URL}/medical_expenses/`;
export const POST_MEDICAL_EXPENSES_API = `${process.env.REACT_APP_API_BASE_URL}/medical_expenses/`;

export const GET_PROVISIONAL_ALLOWANCES_API = `${process.env.REACT_APP_API_BASE_URL}/provisional_allowances/`;
export const POST_PROVISIONAL_ALLOWANCES_API = `${process.env.REACT_APP_API_BASE_URL}/provisional_allowances/`;

export const GET_INTEREST_ADJUSTMENT_API = `${process.env.REACT_APP_API_BASE_URL}/interest_adjustment/`;
export const POST_INTEREST_ADJUSTMENT_API = `${process.env.REACT_APP_API_BASE_URL}/interest_adjustment/`;

export const GET_UNREMITTED_INCOME_API = `${process.env.REACT_APP_API_BASE_URL}/unremitted_income/`;
export const POST_UNREMITTED_INCOME_API = `${process.env.REACT_APP_API_BASE_URL}/unremitted_income/`;

export const GET_TRUST_REIT_DISTRIBUTION_API = `${process.env.REACT_APP_API_BASE_URL}/trust_reit_distribution/`;
export const POST_TRUST_REIT_DISTRIBUTION_API = `${process.env.REACT_APP_API_BASE_URL}/trust_reit_distribution/`;

export const GET_INTEREST_INCOME_API = `${process.env.REACT_APP_API_BASE_URL}/interest_income/`;
export const POST_INTEREST_INCOME_API = `${process.env.REACT_APP_API_BASE_URL}/interest_income/`;

export const GET_OTHER_INCOME_API = `${process.env.REACT_APP_API_BASE_URL}/other_income`;
export const POST_OTHER_INCOME_API = `${process.env.REACT_APP_API_BASE_URL}/other_income/`;

export const POST_FIXED_ASSETS_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}/fixed_assets/`;
export const GET_FIXED_ASSETS_LIST_API = `${process.env.REACT_APP_API_BASE_URL}/fixed_assets/`;

export const POST_DONATIONS_API = `${process.env.REACT_APP_API_BASE_URL}/donations/`;
export const GET_DONATIONS_API = `${process.env.REACT_APP_API_BASE_URL}/donations/`;

export const POST_TAX_COMPUTATION_ADJUSTMENTS_API = `${process.env.REACT_APP_API_BASE_URL}/tax_computation_adjustments/`;
export const GET_TAX_COMPUTATION_ADJUSTMENTS_API = `${process.env.REACT_APP_API_BASE_URL}/tax_computation_adjustments/`;

export const POST_INCOME_TAX_COMPUTATION_API = `${process.env.REACT_APP_API_BASE_URL}/tax_computation_adjustment/income_tax_computation/`;
export const GET_INCOME_TAX_COMPUTATION_API = `${process.env.REACT_APP_API_BASE_URL}/tax_computation_adjustment/income_tax_computation/`;

export const POST_INTELLECTUAL_PROPERTY_RIGHTS_API = `${process.env.REACT_APP_API_BASE_URL}/intellectual_property_rights/`;
export const GET_INTELLECTUAL_PROPERTY_RIGHTS_API = `${process.env.REACT_APP_API_BASE_URL}/intellectual_property_rights/`;

export const POST_FURTHER_OTHER_DEDUCTIONS_API = `${process.env.REACT_APP_API_BASE_URL}/further_other_deductions/`;
export const GET_FURTHER_OTHER_DEDUCTIONS_API = `${process.env.REACT_APP_API_BASE_URL}/further_other_deductions/`;

export const POST_INTELLECTUAL_PROPERTY_API = `${process.env.REACT_APP_API_BASE_URL}/intellectual_property/`;
export const GET_INTELLECTUAL_PROPERTY_API = `${process.env.REACT_APP_API_BASE_URL}/intellectual_property/`;

export const POST_TRAINING_EXPENDITURE_API = `${process.env.REACT_APP_API_BASE_URL}/training_expenditure/`;
export const GET_TRAINING_EXPENDITURE_API = `${process.env.REACT_APP_API_BASE_URL}/training_expenditure/`;

export const GET_UNUTILISED_ITEMS_BROUGHT_FORWARD_CARRIED_FORWARD_API = `${process.env.REACT_APP_API_BASE_URL}/unutilised_items_brought_forward_carry_forward/`;
export const POST_UNUTILISED_ITEMS_BROUGHT_FORWARD_CARRIED_FORWARD_API = `${process.env.REACT_APP_API_BASE_URL}/unutilised_items_brought_forward_carry_forward/`;

export const GET_DISPOSAL_FIXED_ASSETS_API = `${process.env.REACT_APP_API_BASE_URL}/disposal_of_fixed_assets/`;

export const GET_CATEGORIES_LIST_API = `${process.env.REACT_APP_API_BASE_URL}/categories/`;
export const GET_WORKING_LIFE_YEARS_API = `${process.env.REACT_APP_API_BASE_URL}/working_life_years/`;

export const GET_GROUP_RELIEF_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}/group_relief/`;
export const POST_GROUP_RELIEF_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}/group_relief/`;

export const GET_CARRY_BACK_RELIEF_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}/carry_back_relief/`;
export const POST_CARRY_BACK_RELIEF_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}/carry_back_relief/`;

export const GET_FRS_116_ADJUSTMENT_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}/frs_116_adjustments/`;
export const POST_FRS_116_ADJUSTMENT_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}/frs_116_adjustments/`;

export const GET_RENOVATION_AND_REFURBISHMENT_WORKS_API = `${process.env.REACT_APP_API_BASE_URL}/renovation_and_refurbishment_works/`;
export const POST_RENOVATION_AND_REFURBISHMENT_WORKS_API = `${process.env.REACT_APP_API_BASE_URL}/renovation_and_refurbishment_works/`;

export const SEND_MAIL_API = `${process.env.REACT_APP_API_BASE_URL}/users/send_credentials/`;
export const SEND_CUSTOMER_DETAILS_MAIL_API = `${process.env.REACT_APP_API_BASE_URL}/customer/send_customer_details/`;

export const GET_SHAREHOLDING_TEST_CAPITAL_ALLOWANCES_API = `${process.env.REACT_APP_API_BASE_URL}/shareholding_test_capital_allowances/`;
export const GET_SHAREHOLDING_TEST_UNABSORBED_LOSSES_API = `${process.env.REACT_APP_API_BASE_URL}/shareholding_test_unabsorbed_losses/`;

export const GET_FTC_POOLING_API = `${process.env.REACT_APP_API_BASE_URL}/foreign_tax_credit_computation/`

export const GET_INNOVATION_PROJECT_WITH_PARTNER_INSTITUTION_API = `${process.env.REACT_APP_API_BASE_URL}/partner_institution_innovation_projects/`

export const GET_RESEARCH_AND_DEVELOPMENT_ACTIVITIES_API = `${process.env.REACT_APP_API_BASE_URL}/rd_activities/`

export const FORM_CS_API = `${process.env.REACT_APP_API_BASE_URL}/form_cs/`

export const FORM_C_API = `${process.env.REACT_APP_API_BASE_URL}/form_c/`

export const FORM_CS_LITE_API = `${process.env.REACT_APP_API_BASE_URL}/form_cs_lite/`
